import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as sH from 'sanitize-html'

const Wrapper = styled.section`
  ${tw`max-w-7xl mx-auto py-16 md:flex md:flex-wrap items-center`}
`

const Column = styled.div`
  ${tw`md:w-1/2 px-8 mb-10 md:mb-0`}
`

const Content = styled.div`
  ${tw`max-w-3xl mx-auto lg:pl-10`}
  p a {
    ${tw`font-bold text-primary border-b border-primary pr-4 no-underline inline-block mt-5`}
  }
`

const Columns = ({ data: { columns } = {} }) => (
  <Wrapper>
    {columns.map(({ columnImage, text }, index) => {
      const imageGatsbyData = getImage(columnImage?.localFile.childImageSharp.gatsbyImageData)
      return (
        <Column key={index}>
          {columnImage && <GatsbyImage image={imageGatsbyData} alt={columnImage.title} />}
          {text && <Content className="prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: sH(text) }} />}
        </Column>
      )
    })}
  </Wrapper>
)

export default Columns

Columns.propTypes = {
  data: PropTypes.object.isRequired,
}

export const columnsData = graphql`
  fragment Columns on WpPage_Pagebuilder_Layouts_Columns {
    columns {
      type
      columnImage: image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 960)
          }
        }
        title
      }
      text
    }
    fieldGroupName
  }
`
